import { defineComponent } from 'vue';

export const csvMixin = defineComponent({
  methods: {
    arrayFromCsv (file: File): Promise<string[][]> {
      return new Promise((resolve) => {
        const csvType = /ms-excel|text\/csv/;
        if (file.type.match(csvType)) {
          const reader = new FileReader();
          reader.onload = () => {
            resolve(this.processCsv(reader.result as string));
          };
          reader.readAsText(file);
        } else {
          resolve([]);
        }
      });
    },
    async saveFiles (fileInput : HTMLInputElement): Promise<{translations: string[][], file: File}[]> {
      let files = Array.from(fileInput.files ?? []);
      const outputs = [];
      if (files.length === 0) {
        return [];
      }
      files = files.sort((a, b) => b.name.localeCompare(a.name));
      for (const file of files) {
        const translations = await this.arrayFromCsv(file);
        outputs.push({ translations, file });
      }
      fileInput.value = '';
      return outputs;
    },
    processCsv (dataString: string): string[][] {
      const translations: [string, string][] = dataString
        .split(/\n/)
        .map(lineStr => lineStr
          .split(/"?;"?/)
          .map(l => l.replace(/^"?\s*|\s*"?\s*$/g, ''))
          .filter(v => v !== ''))
        .filter(e => e.length >= 2)
        .map(e => {
          if (e[1].match(/^\[.*]$/)) {
            return [e[2], e[0]];
          }
          return [e[1], e[0]];
        });

      return this.sanitiseInput(translations);
    },
    sanitiseInput (translations: [string, string][]) {
      return translations.map(t => t.map(v => {
        const matchesToRemove = [
          /(\bno\b)?\spl\b/,
          /\([^)]*\)\s?/g,
          /(?<!(\.{3}|…)),.*/,
          /\bAE\b/,
          /\bBE\b/,
          /\s*\/[^ ]*\b/g,
          /\s*\/.*$/g,
          /(?<![ap]\.m)[.,!?;-]$/
        ];
        const matchesToReplace: [RegExp, string][] = [
          [/\bsth\b\.?/g, 'something'],
          [/\betw\b\.?/g, 'etwas'],
          [/\bsb\b\.?/g, 'somebody'],
          [/…/g, '...'],
          [/\bjdn\b\.?/g, 'jemanden']
        ];
        v = v.trim();
        matchesToReplace.forEach(m => { v = v.replace(m[0], m[1]); });
        matchesToRemove.forEach(m => { v = v.replace(m, ''); });
        return v;
      }));
    }
  }
});
