
import { defineComponent, PropType } from 'vue';
import { Book } from '@/models';
import { mapState } from 'vuex';

export default defineComponent({
  name: 'BookCover',
  emits: ['newCover'],
  props: {
    book: { type: Object as PropType<Book> }
  },
  methods: {
    async newCover (event: Event) {
      const input = event.target as HTMLInputElement;
      const files = Array.from(input.files ?? []);
      if (files.length === 0) {
        return;
      }
      this.$emit('newCover', files[0]);
    }
  },
  computed: {
    ...mapState({ user: 'user' })
  }
});
