
import { defineComponent } from 'vue';
import { csvMixin } from '@/mixins/csvFile';

export default defineComponent({
  data () {
    return {
      lessonName: ''
    };
  },
  mixins: [csvMixin],
  emits: ['addLesson'],
  methods: {
    async addLesson () {
      if (!this.lessonName.length) {
        return;
      }
      const fileInput = this.$refs.fileInput as HTMLInputElement;
      const files = Array.from(fileInput.files ?? []);
      let translations: string[][] = [];
      if (files.length === 1) {
        translations = await this.arrayFromCsv(files[0]);
      }
      (this.$refs.dismissButton as HTMLButtonElement).click();
      this.$emit('addLesson', { lessonName: this.lessonName, translations: translations });
    }
  }
});
